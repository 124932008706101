@import "src/scss/variables";
@import "src/scss/mixins";

.benefits-items {
  width: 100%;
  max-width: 1167px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: $screen-md) {
    padding: 0 20px;
  }

  @media (max-width: $screen-sm) {
    flex-direction: column;
  }

  .item {
    @include description-typography;
    font-weight: 600;
    font-size: 20px;
    line-height: 170%;
    color: $light-color;
    padding-left: 40px;
    box-sizing: border-box;
    width: 100%;
    background-image: url("../../../assets/img/0.svg");
    background-repeat: no-repeat;
    background-position: 0px 4px;
    background-size: 20px 20px;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  li {
    margin-top: 40px;
  }
}
