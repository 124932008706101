@import "src/scss/variables";
@import "src/scss/mixins";

html,
body {
  height: 100%;
}

body {
  @include base-font;
  @include base-font-weight;

  margin: 0;
  background-color: $background-color;
}

.link {
  @include base-animation;

  color: $light-color;
  text-decoration: none;

  &:visited {
    color: $light-color;
  }

  &:hover, &.active {
    @include base-animation;
    color: $main-color;
    cursor: pointer;

    .icon {
      @include base-animation;
      background-color: $main-color;
    }
  }

  .icon {
    @include base-animation;
    background-color: $light-color;
  }
}
