@import "../variables";


@mixin base-animation() {
  transition: all $animation-speed;
}

@mixin header-background {
  background: rgba(17, 20, 26, 0.7);
  mix-blend-mode: normal;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
}

@mixin link-separator {
  position: relative;

  &:before {
    position: absolute;
    content: "|";
    left: -13px;
    color: #636363;
  }

  &:first-child:before {
    content: "";
  }
}

@mixin base-chip-item {
  border-radius: $submit-border-radius-xs;

  font-size: 12px;
  padding: 8px 25px 7px;

  @media (min-width: $screen-lg-min) {
    border-radius: $submit-border-radius-md;
  }

  @media (min-width: $screen-xlg-min) {
    padding: 12px 40px 8px;
  }
}

