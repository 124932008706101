@import "src/scss/variables";
@import "src/scss/mixins";

.promo {
  @include block-horizontal-paddings;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  background: url("../../../assets/img/promo-bg.jpg") no-repeat center;
  background-size: cover;
  color: $light-color;

  @media (min-width: $screen-lg) {
    padding-top: 0;
    flex-direction: row;
    justify-content: space-between;
    height: 100vh;
  }

  @media (min-width: $screen-sm-min) {
    height: 100vh;
    min-height: 1200px;
  }

  @media (min-width: $screen-lg-min) {
    height: 100vh;
    min-height: 768px;
  }

  @media (max-width: $screen-sm) {
    height: auto;
    padding-top: 120px;
    padding-bottom: 50px;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 101px;
    background: url("../../../assets/img/parallax-ripped-2.png") no-repeat center;
    background-size: 100% 100%;

    @media (max-width: $screen-sm) {
      height: 50px;
      background-size: cover;
    }
  }

  .center-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: $screen-xlg-max) {
      order: 2;
    }
  }

  .left-block {
    display: none;

    @media (min-width: $screen-lg) {
      display: block;
      width: 329px;
      height: 376px;
      background: url("../../../assets/img/promo-nft-big.png") no-repeat center;
      background-size: contain;
    }
  }

  .right-block {
    width: 608px;
    height: 319px;
    background: url("../../../assets/img/promo-argo-nft-mobile.png") no-repeat center;
    background-size: contain;
    margin: 160px 0;

    @media (max-width: $screen-xlg-max) {
      order: 2;
      margin: 80px 0;
    }

    @media (max-width: $screen-md) {
      width: 568px;
      height: 298px;
      margin: 80px 0;
      min-height: 270px;
    }

    @media (max-width: $screen-sm) {
      width: 320px;
      height: 168px;
      margin: 0 0 80px;
      min-height: 116px;
      order: 1;
    }

    @media (min-width: $screen-lg) {
      width: 349px;
      height: 381px;
      margin: 0;
      background: url("../../../assets/img/promo-argo-big.png") no-repeat center;
      background-size: contain;
    }
  }

  .description {
    @include description-typography;
    margin-top: 40px;
    width: 100%;
    max-width: none;
    text-align: center;

    @media (max-width: $screen-sm) {
      font-weight: 600;
      font-size: 16px;
      line-height: 170%;
    }
  }

  .social-links {
    display: flex;
    margin-top: 40px;

    @media (max-width: $screen-sm) {
      flex-direction: column;
    }

    .item {
      display: block;
      background-size: 320px 56px;
      width: 320px;
      height: 56px;
      text-indent: -9999px;
      background-repeat: no-repeat;
      background-position: bottom;

      @media (max-width: $screen-sm) {
        margin-bottom: 32px;
      }

      @media (min-width: $screen-sm-min) {
        background-size: 182px 56px;
        width: 182px;
        &:hover {
          background-size: 182px 46px;
        }
      }

      &.twitter {
        background-image: url("../../../assets/img/twitter-big.svg");

        @media (min-width: $screen-sm-min) {
          background-image: url("../../../assets/img/twitter.svg");
          &:hover {
            background-image: url("../../../assets/img/twitter-hover.svg");
          }
        }
      }

      &.instagram {
        margin-left: 40px;
        background-image: url("../../../assets/img/s-inst-big.svg");

        @media (max-width: $screen-sm) {
          margin-left: 0;
        }

        @media (min-width: $screen-sm-min) {
          background-image: url("../../../assets/img/s-inst.svg");
          &:hover {
            background-image: url("../../../assets/img/s-inst-h.svg");
          }
        }
      }

      &.tiktok {
        margin-left: 40px;
        background-image: url("../../../assets/img/s-tik-big.svg");

        @media (max-width: $screen-sm) {
          margin-left: 0;
        }

        @media (min-width: $screen-sm-min) {
          background-image: url("../../../assets/img/s-tik.svg");
          &:hover {
            background-image: url("../../../assets/img/s-tik-h.svg");
          }
        }
      }

      &.discord {
        margin-left: 40px;
        background-image: url("../../../assets/img/discord-big.svg");

        @media (max-width: $screen-sm) {
          margin-left: 0;
        }

        @media (min-width: $screen-sm-min) {
          background-image: url("../../../assets/img/discord.svg");
          &:hover {
            background-image: url("../../../assets/img/discord-hover.svg");
          }
        }
      }

      &.opensea {
        margin-left: 40px;
        background-image: url("../../../assets/img/opensea-big.svg");

        @media (max-width: $screen-sm) {
          margin: 0;
        }

        @media (min-width: $screen-sm-min) {
          background-image: url("../../../assets/img/opensea.svg");
          &:hover {
            background-image: url("../../../assets/img/opensea-hover.svg");
          }
        }
      }
    }
  }

  .title-image {
    width: 100%;
    max-width: 605px;

    h1 {
      @include argo-font;
      text-align: center;
      font-size: 56px;
      line-height: 150%;
      font-weight: 400;
      margin: 0;

      @media (max-width: $screen-sm) {
        font-size: 32px;
      }
    }

    @media (max-width: $screen-sm) {
      background-size: contain;
      max-height: 170px;
    }
  }
}
