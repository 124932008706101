@mixin base-font {
  font-family: "Spartan", "Helvetica Neue", sans-serif;
}

@mixin argo-font {
  font-family: "Argo", "Spartan", sans-serif;
}

@mixin pixel-font {
  font-family: "Press Start 2P", "Spartan", sans-serif;
}

@mixin digit-font {
  font-family: "Digital Numbers", "Spartan", sans-serif;
}

@mixin base-font-style-normal {
  font-style: normal;
}

@mixin base-font-weight {
  @include base-font-style-normal;

  font-weight: 500;
}

@mixin description-typography {
  //font-size: 1.25rem; // 20px
  font-size: clamp(1.625rem, calc(0.9443rem + 0.2548vw), 3rem);
  line-height: 170%;
}

@mixin parallax-description-typography {
  //font-size: 2.750rem; // 44px
  font-size: clamp(1.625rem, calc(1.4578rem + 0.7643vw), 2.375rem); // 38px
  line-height: 170%;
}

@mixin pixel-title-typography {
  @include pixel-font;
  font-weight: 400;
  //font-size: 3.375rem; // 54px
  //line-height: 3.375rem; // 54px

  //font-size: clamp(1rem, calc(0.7213rem + 1.2739vw), 2.25rem); // 36px
  //line-height: clamp(1rem, calc(0.7213rem + 1.2739vw), 2.25rem);

  font-size: 42px;
  line-height: 56px;
  text-align: center;
}

@mixin mechanics-information-typography {
  font-size: clamp(1.25rem, calc(0.75rem + 0.4125vw), 1.5rem); // 24px
  line-height: 170%;
  font-weight: 500;
}

@mixin crew-role-typography {
  font-size: 1rem; // 16px
  line-height: 1.125rem; // 18px
  font-weight: 600;
}

@mixin crew-name-typography {
  font-size: 1.625rem; // 26px
  line-height: 1.813rem; // 29px
  font-weight: 800;
}

@mixin purpose-description-typography {
  font-size: 1.250rem; // 20px
  line-height: 193%;
  font-weight: 600;
}

@mixin card-title-typography {
  font-size: clamp(1.625rem, calc(1.4578rem + 0.7643vw), 2.375rem); // 38px
  line-height: 150%;
  font-weight: 800;
}

@mixin card-description-typography {
  font-size: clamp(0.875rem, calc(0.7914rem + 0.3822vw), 1.25rem); // 14px - 20px
  line-height: 160%;
  font-weight: 600;
}

@mixin footer-link-typography {
  font-size: 1.250rem; // 20px
  line-height: 1.375rem; // 22px
  font-weight: 600;
}

@mixin base-link-typography {
  font-size: 0.875rem; // 14px
  font-weight: 600;
  line-height: 1rem; // 16px
}

@mixin side-menu-link-typography {
  @include base-link-typography;
  letter-spacing: 0.25px;
}

@mixin sidemenu-subscribe-button-typography {
  font-size: 1.125rem; // 18px
  line-height: 1.750rem; // 28px
  letter-spacing: 0.45px;
}

@mixin sign-button-typography {
  font-size: 1rem; // 16px
  line-height: 1.250rem; // 20px
  letter-spacing: 0.45px;
}

@mixin base-typography {
  font-size: 0.750rem; // 12px
  line-height: 0.813rem; // 13px
}

@mixin navigation-link-typography {
  @include base-typography;

  letter-spacing: 1.2000000476837158px;
}

@mixin promo-title {
  @include argo-font;
  font-size: 3.5rem;
  line-height: 150%;
}
