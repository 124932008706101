@import "src/scss/variables";
@import "src/scss/mixins";

.full-page-wrapper {
  @include base-animation;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  z-index: 101;

  &.slide-enter-done {
    background-color: rgba(0, 0, 0, 0.5);
  }
  &.slide-enter-active {
    background-color: rgba(0, 0, 0, 0.5);
    transition: background-color 300ms;
  }

  &.slide-exit-done {
    background-color: rgba(0, 0, 0, 0);
  }

  &.slide-exit-active {
    background: rgba(0, 0, 0, 0);
    transition: background-color 300ms;
  }
}

.side-menu {
  @include base-animation;
  height: 100%;
  width: 400px;
  background-color: $background-color;
  box-sizing: border-box;
  overflow-y: auto;
  will-change: transform;
  transform: translateX(-100%);

  .slide-enter-done & {
    transform: translateX(0);
  }
  .slide-enter-active & {
    transform: translateX(0);
    transition: transform 300ms;
  }

  .slide-exit-done & {
    transform: translateX(-100%);
  }

  .slide-exit-active & {
    transform: translateX(-100%);
    transition: transform 300ms;
  }

  @media (max-width: $screen-sm) {
    width: 100%;
  }

  .wrapper {
    padding: 24px 48px 80px 48px;
    box-sizing: border-box;
  }

  .main-menu-button span {
    width: 24px;
  }

  .side-header {
    display: flex;
    align-items: center;

    .logo {
      margin-left: 32px;
      height: 40px;

      .img {
        height: 100%;
      }
    }
  }

  .sidemenu-links {
    margin-top: 40px;

    ul.sidemenu-nav {
      list-style: none;
      padding: 0;
      margin: 0;
      box-sizing: border-box;

      &:hover {
        a {
          opacity: 0.5;

          &:hover {
            opacity: 1;
            color: $light-color;
          }
        }
      }

      li {
        list-style: none;
        padding: 0;
        margin: 0;
        border-bottom: 1px solid rgba($light-color, 0.3);
        box-sizing: border-box;

        &:last-child {
          border-bottom: none;
        }

        a {
          @include base-animation;
          @include side-menu-link-typography;
          display: block;
          align-self: center;
          letter-spacing: 0.16px;
          text-transform: uppercase;
          padding: 24px 0;
          box-sizing: border-box;
        }
      }
    }
  }

  .store-links {
    margin-top: 40px;
  }

  .subscribe-button {
    @include sidemenu-subscribe-button-typography;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 64px;
    padding: 11px 22px;
    background: $main-color;
    border-radius: 10000px;
    border: transparent solid 2px;
    white-space: nowrap;

    &:hover {
      color: $light-color;
      background: transparent;
      border: $light-color solid 2px;
    }
  }
}

.side-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}

.side-footer-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  margin: 56px 0;

  @media (max-width: $screen-sm) {
    display: none;
  }
}

.side-apple-button, .side-google-button, .side-roku-button {
  width: 120px;
  height: 40px;
  background-size: 120px auto;
  margin-top: 17px;
  margin-right: 17px;
  font-size: 0;
  transition: 0.2s;
  opacity: 1;

  @media (min-width: $screen-sm-max) {
    .side-footer-row:hover &:not(:hover) {
      opacity: .66;
    }
  }
}

.side-apple-button {
  background: url("../../../assets/img/apple.svg") no-repeat center;
}

.side-google-button {
  background: url("../../../assets/img/google.svg") no-repeat center;
}

.side-roku-button {
  background: url("../../../assets/img/roku.svg") no-repeat center;
}


