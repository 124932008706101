@import "../variables";

@mixin block-horizontal-paddings {
  padding: 0 $base-margin-lg;
  box-sizing: border-box;

  @media (max-width: $screen-xlg-min) {
    padding: 0 $base-margin;
  }

  @media (max-width: $screen-sm) {
    padding: 0 calc(#{$base-margin} / 2);
  }
}

@mixin light-block-styles {
  @include block-horizontal-paddings;
  background: $background-light-color;
}

@mixin roadmap-description-typography {
  font-size: clamp(0.75rem, 0.6385rem + 0.5096vw, 1.25rem); // 12px - 20px
  line-height: 193%;
  font-weight: 600;
}

@mixin roadmap-percent-typography {
  @include pixel-font;
  font-size: clamp(1.25rem, 1.0549rem + 0.8917vw, 2.125rem); // 20px - 34px
  line-height: clamp(1.25rem, 1.0549rem + 0.8917vw, 2.125rem); // 20px - 34px
}

@mixin pixelated-border {
  border: solid 30px;
  border-image: url("../../assets/img/border-white.png") 30;
}

@mixin strange-border {
  border: solid 10px;
  border-image: url("../../assets/img/border.png") 10;
}

@mixin parallax {
  @media (min-width: $screen-sm-min) {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@mixin mobile-padding {
  @media (max-width: $screen-sm) {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@mixin passed-white-line {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  height: 100%;
  width: 0;
  z-index: 1;
  border-right: 4px solid #EEE9E4;
  transform: translateX(-2px);
}
