@import "src/scss/variables";
@import "src/scss/mixins";

.header {
  @include header-background;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  transition: .3s;

  &.transparent {
    background: transparent;
    backdrop-filter: none;
    box-shadow: none;
  }

  .main-container {
    height: $catalog-header-height;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $screen-sm) {
      height: 72px;
    }
  }

  .logo.argo {
    margin-left: 32px;
    margin-top: 8px;

    .img {
      height: 40px;
    }
  }

  .menu {
    display: none;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: $screen-lg-min) {
      display: flex;
    }

    &:hover, &:focus, &.has-active {
      .link {
        color: #8A8989;

        &:hover, &:focus, &.is-active {
          color: $light-color;
        }
      }
    }

    .link {
      @include base-link-typography;
      @include link-separator;
      display: block;
      text-transform: uppercase;
      margin: 0 40px;
      letter-spacing: 0.16px;

      &:before {
        left: -43px;
      }
    }
  }

  &__mint-button {
    background: url("../../../assets/img/mint-min.svg") no-repeat bottom;
    background-size: 154px 48px;
    border: none;
    width: 154px;
    height: 48px;
    text-indent: -9999px;

    &.active {
      display: none;
    }

    @media (min-width: $screen-sm) {
      &:hover {
        background-image: url("../../../assets/img/mint-min-hover.svg");
        background-size: 154px 40px;
      }
    }

    @media (max-width: $screen-sm) {
      width: 102.67px;
      height: 32px;

      background-size: 102.67px 32px;
    }
  }

  .login-menu {
    display: flex;
    align-items: center;
    width: 24px;
    height: 24px;
    flex-direction: column;
    justify-content: flex-start;
  }

  .search {
    display: none;
    margin-right: $base-margin;

    @media (min-width: $screen-md) {
      display: block;
    }

    .link {
      @include base-link-typography;
      display: flex;
      align-items: center;
      min-height: 30px;
      line-height: 30px;
      text-transform: uppercase;
    }

    .icon {
      display: block;
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }

    .title {
      display: none;

      @media (min-width: $screen-lg) {
        display: inline;
      }
    }
  }
}
