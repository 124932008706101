@import "src/scss/variables";
@import "src/scss/mixins";

.nft-road-map {
  background: $color-white;
  padding-bottom: 120px;

  @media (max-width: $screen-sm) {
    padding-bottom: 80px;
  }

  &__title {
    @include pixel-title-typography;
    margin-top: 0;
    margin-bottom: 120px;
    padding-top: 138px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $screen-sm) {
      font-size: 24px;
      line-height: 38px;
      margin-bottom: 64px;
      padding-top: 56px;
    }

    span {
      display: inline-block;
      margin: 0 56px;

      @media (min-width: $screen-sm) {
        br {
          display: none;
        }
      }
    }

    &::before, &::after {
      content: '';
      height: 34px;
      flex: 1;
    }

    &::before {
      background: url("../../../assets/img/road-map-title-before.svg") no-repeat right;
    }

    &::after {
      background: url("../../../assets/img/road-map-title-after.svg") no-repeat left;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    @media (max-width: $screen-lmd) {
      margin: 0 20px;
    }

    @media (max-width: $screen-sm) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__aim {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;

    @media (max-width: $screen-sm) {
      margin-bottom: 40px;
    }
  }

  &__aim-text {
    font-weight: 800;
    font-size: 48px;
    line-height: 150%;
    color: $color-black;
    width: 100%;
    max-width: 503px;
    margin: 0;
    padding-right: 80px;

    @media (max-width: $screen-md) {
      font-size: 28px;
      line-height: 150%;

      .underlined {
        margin-right: 14px;
      }
    }

    @media (max-width: $screen-sm) {
      max-width: none;
      padding: 0;
    }

    .underlined {
      position: relative;
      display: inline-block;

      @media (min-width: 400px) {
        white-space: nowrap;
      }

      &::after {
        content: "";
        position: absolute;
        width: calc(100% + 24px);
        height: 60%;
        left: -12px;
        top: 50%;
        background: rgba(0, 87, 253, 0.1);
        transform: matrix(-1, 0, 0, 1, 0, 0);
      }
    }

    @media (min-width: $screen-sm-max) {
      .lg {
        display: inline;
      }
      .sm {
        display: none;
      }
    }

    @media (max-width: $screen-sm) {
      .lg {
        display: none;
      }
      .sm {
        display: inline;
      }
    }
  }

  &__detail {
    margin: 0;
    flex: 1;
    position: relative;
    list-style: none;
    padding-left: 0;
  }

  &__detail-item {
    font-weight: 600;
    font-size: 20px;
    line-height: 193%;
    color: $color-black;
    width: calc(100% - 52px);
    max-width: 607px;
    padding-left: 52px;
    position: relative;
    text-transform: uppercase;

    @media (max-width: $screen-md) {
      font-size: 14px;
      line-height: 193%;
    }

    &::before {
      content: "";
      position: absolute;
      width: 0;
      height: 100%;
      left: 10px;
      top: 31px;
      border-right: 1px dashed #6A767E;
      z-index: 0;
      transform: translateX(-50%);
    }

    &:last-child::before {
      display: none;
    }

    &:not(:last-child) {
      padding-bottom: 40px;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 12px;
      width: 20px;
      height: 20px;
      background: url("../../../assets/img/rectangle.svg") no-repeat center;
      z-index: 1;
    }

    a {
      color: $color-blue !important;
    }
  }

  .scissor-divider {
    padding: 166px 0 120px;

    @media (max-width: $screen-sm) {
      padding: 76px 0;
    }

    .hr::after {
      background-color: $color-black;
      @media (max-width: $screen-sm) {
        left: 50% !important;
      }
    }

    &.left .hr::after {
      left: 20%;
    }

    &.right .hr::after {
      left: 72%
    }
  }
}
