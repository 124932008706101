@import "../variables";


@mixin base-button {
  border-radius: $submit-border-radius-xs;

  &:active, &:focus {
    outline: none;
  }
}

@mixin button-ghost {
  @include base-button;
  @include base-animation;

  color: $light-color;
  padding: 5px 25px;
  font-size: 14px;
  border: $light-color solid 2px;
  background-color: transparent;

  &:hover {
    cursor: pointer;
    color: $main-color;
    border-color: $main-color;
  }
}

@mixin button-danger {
  @include base-button;
  @include base-animation;

  color: $light-color;
  padding: 5px 25px;
  font-size: 14px;
  background-color: $error-color;
  border: $error-color solid 2px;

  &:hover {
    cursor: pointer;
    color: $error-color;
    background-color: transparent;
  }
}
