@import "src/scss/variables";
@import "src/scss/mixins";

.decorated-link:hover {
  @media (min-width: $screen-sm) {
    text-decoration: none;
  }
}
//:host::ng-deep {
.mark {
  color: $main-color;

  &.negative {
    color: inherit;
    background: $main-color;
    display: inline-block;
    padding: 0 12px;
    margin-right: -1px;

    &.dot {
      padding: 0;
    }
  }
}

//}

.parallax {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: $screen-sm) {
    padding-top: 316px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: -50px;
    width: 100%;
    height: 100px;
    background: url("../../assets/img/ripped-both-sides.png") no-repeat center;
    background-size: 100% 100%;
    z-index: 1;

    @media (min-width: $screen-qhd-min) {
      top: -70px;
      height: 140px;
    }

    @media (max-width: $screen-sm) {
      height: 50px;
      top: -25px;
      background-size: cover;
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    width: 100%;
    height: 101px;
    background: url("../../assets/img/parallax-ripped-2.png") repeat-x center;
  }

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #F9D040;
    overflow: hidden;
    @include parallax;

    @media (max-width: $screen-sm) {
      top: 0;
    }

    &:before {
      content: '';
      position: absolute;
      width: 300px;
      height: 300px;
      background: url("../../assets/img/second-collection-bg-left.svg") repeat-x center;
      background-size: contain;
      left: 80px;
      top: 50%;
      margin-top: -150px;

      @media (max-width: $screen-sm) {
        width: 156px;
        height: 156px;
        top: 80px;
        margin-top: 0;
        left: 50%;
        margin-left: -78px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      width: 194px;
      height: 580px;
      background: url("../../assets/img/second-collection-bg-right.svg") repeat-x center;
      right: 116px;
      top: 50%;
      margin-top: -290px;

      @media (max-width: $screen-sm) {
        display: none;
      }
    }
  }

  .description {
    @include parallax-description-typography;
    font-weight: 700;
    width: calc((970 / 1920) * 100%);
    color: $black-color;
    text-align: center;
    position: relative;
    z-index: 1;
    padding: calc((250 / 1920) * 100%) 0;
    box-sizing: border-box;

    @media (min-width: $screen-sm) {
      min-width: 746px;
      max-width: 890px;
    }

    @media (min-width: $screen-md) {
      min-width: 932px;
    }

    @media (max-width: $screen-sm) {
      width: 90%;
      padding: 92px 0;
      font-size: 32px;
      line-height: 170%;
    }

    .sign-up-early {
      margin-top: 40px;

      .sign-up-early-link {
        display: inline-block;
        width: 425px;
        height: 56px;
        text-indent: -9990px;
        background: url("../../assets/img/button-sign-early-access.svg") no-repeat center;
        background-size: contain;

        @media (max-width: $screen-sm) {
          width: 360px;
          height: 56px;
        }

        @media (max-width: $screen-xs) {
          //position: relative;
          //left: -5vw;
          width: 100%;
          height: 50px;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

.mechanics-block {
  @include light-block-styles;
  position: relative;
  z-index: 1;
  padding: 80px 0;
  box-sizing: border-box;
  //padding-bottom: 120px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @media (max-width: $screen-sm) {
    padding-top: 43px !important;
    padding-bottom: 40px !important;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: $screen-sm) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__title {
    @include pixel-title-typography;
    margin-right: 64px;
    text-align: left;
    line-height: 56px;
    white-space: nowrap;
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 100%;
      width: 943px;
      height: 68px;
      background: url(../../assets/img/bricks.svg) no-repeat right;
      background-size: auto 100%;
      margin-right: 56px;

      @media (max-width: $screen-sm) {
        margin-right: 20px;
        height: 56px;
      }

      @media (max-width: $screen-xxs) {
        display: none;
      }
    }

    @media (max-width: $screen-sm) {
      margin: 0 0 40px 62px;
      font-weight: normal;
      font-size: 32px;
      line-height: 48px;
    }

    @media (max-width: $screen-xxs) {
      margin-left: 0;
    }
  }

  &__description {
    font-weight: bold;
    font-size: 20px;
    line-height: 170%;
    height: fit-content;
    width: 100%;
    max-width: 976px;
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 100%;
      width: 300px;
      height: 68px;
      background: url(../../assets/img/thick-bricks.png) no-repeat left;
      background-size: auto 100%;
      margin-left: 56px;
    }

    @media (max-width: $screen-sm) {
      font-weight: bold;
      font-size: 16px;
      line-height: 170%;
    }
  }
}

.information-block {
  @include mechanics-information-typography;
  margin-top: -1px;
  padding-top: 120px;
  padding-bottom: 80px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  //height: 178px;
  background: $background-light-color;

  @media (max-width: $screen-sm) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &__content {
    width: calc((670 / 1920) * 100%);
    margin: 0 56px;

    @media (max-width: $screen-sm) {
      width: 100%;
      margin: 0 20px;
      font-size: 14px;
      line-height: 193%;

      & br {
        display: none;
      }
    }
  }

  &__thin-bricks {
    width: calc((310 / 1920) * 100%);
    background: url("../../assets/img/thin-bricks.png") repeat-y right;

    @media (max-width: $screen-sm) {
      background-size: 120px auto;
      width: calc((290 / 1920) * 100%);
      max-height: 56px;
    }
  }

  &__thick-bricks {
    width: calc((320 / 1920) * 100%);
    background: url("../../assets/img/thick-bricks.svg") repeat-y left;
    flex: 1;
  }
}

.image-block {
  width: 100%;
  height: calc(100vw / 2.2);
  min-height: 305px;
  position: relative;
  background-image: url("../../assets/img/bad-assistant.jpg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;

  @media (max-width: $screen-sm) {
    height: calc(100vw / 1.05);
    background-image: url("../../assets/img/mob.jpg");
  }


  &:before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 101px;
    background: url("../../assets/img/ripped-3.png") repeat-x top;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 101px;
    background: url("../../assets/img/parallax-ripped-2.png") no-repeat center;
    background-size: 100% 100%;

    @media (max-width: $screen-sm) {
      height: 50px;
      background-size: cover;
    }
  }

  &__title {
    //@include pixel-font;
    font-style: normal;
    font-weight: normal;
    font-size: calc(100vw / 71);
    line-height: calc(100vw / 60);
    text-align: center;
    color: #EEE9E4;
    margin: 0 0 0 0;
    padding: calc(100vw / 17) 15px 0;
    box-sizing: border-box;

    @media (max-width: $screen-lmd) {
      padding: calc(100vw / 14) 15px 0;
    }

    @media (max-width: $screen-sm) {
      padding: 80px 15px 0;
      font-size: 20px;
      line-height: 20px;
    }

    @media (max-width: $screen-xxs) {
      padding: 80px 15px 0;
      font-size: 16px;
      line-height: 16px;
    }
  }
}

//:host::ng-deep {
.scissor-divider {
  padding: 120px 0;
  box-sizing: border-box;
  background: $background-light-color;

  @media (max-width: $screen-sm) {
    padding: 44px 0 76px;
  }

  & {
    background: transparent;

    .hr {
      position: relative;
      border-top: $main-color dashed 4px;

      &:after {
        content: '';
        position: absolute;
        top: -22px;
        transform: translateX(-50%);
        left: 50%;
        width: 48px;
        height: 40px;
        background-color: #FFFFFF;
        -webkit-mask-image: url(../../assets/img/scissors.svg);
        mask-image: url(../../assets/img/scissors.svg);
        mask-size: contain;

        @media (max-width: $screen-sm) {
          width: 24px;
          height: 20px;
          top: -12px;
        }
      }
    }
  }

  &.light .hr {
    border-top-color: $light-color;
  }
}

//}

.crew-block {
  background: $background-light-color;
  box-sizing: border-box;
  padding: 0 0 32px 0;
  margin-top: -1px;
  position: relative;

  @media (max-width: $screen-sm) {
    //padding: 0 0 138px 0;
  }

  &__title {
    @include pixel-title-typography;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 46px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $screen-sm) {
      font-size: 24px;
      line-height: 38px;
      margin-bottom: 0;
      padding-top: 56px;
    }

    .text {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      line-height: 170%;

      &::before,
      &::after {
        content: '';
        display: inline-block;
        width: 55px;
        height: 131px;
        margin: 0 56px;
        background: url("../../assets/img/man.svg") no-repeat center;
        background-size: auto 100%;

        @media (max-width: $screen-sm) {
          height: 80px;
          margin: 0 10px !important;
        }
      }

      &::before {
        background-image: url("../../assets/img/man.svg");
        margin-right: 32px;
      }

      &::after {
        background-image: url("../../assets/img/woman.svg");
        margin-left: 32px;
      }

      @media (min-width: $screen-sm) {
        br.spaceship {
          display: none;
        }
      }
    }

    &::before, &::after {
      content: '';
      height: 34px;
      flex: 1;
    }

    &::before {
      background: url("../../assets/img/road-map-title-before.svg") no-repeat right;
    }

    &::after {
      background: url("../../assets/img/road-map-title-after.svg") no-repeat left;
    }
  }

  &__items {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    max-width: 1482px;
    color: $pure-white;
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;

    @media (max-width: $screen-sm) {
      flex-direction: column;
      align-items: center;
    }

    .item {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;
      height: 448px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      border-radius: 40px;

      @media (min-width: 769px) {
        &:first-of-type {
          margin-right: 56px;
        }
      }

      @media (max-width: 1520px) {
        width: 543px;
        height: 351px;
      }

      @media (max-width: $screen-lg) {
        width: 448px;
        height: 290px;
      }

      @media (max-width: $screen-md) {
        width: 336px;
        height: 219px;
      }

      @media (max-width: $screen-sm) {
        width: calc(100vw - 40px);
        height: calc(100vw - 40px);
        background-size: cover;

        &:first-of-type {
          margin-bottom: 40px;
        }
      }

      &.jason {
        background-image: url("../../assets/img/jason.jpg");
      }

      &.paige {
        background-image: url("../../assets/img/paige.jpg");
      }
    }

    .person-info {
      padding: 32px 0;
      box-sizing: border-box;
      text-align: center;
    }

    .role {
      @include crew-role-typography;
    }

    .name {
      text-transform: uppercase;
      @include crew-name-typography;
      margin-top: 8px;
    }
  }

  &__posters {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    width: 100%;
    height: 425px;
    //position: absolute;
    z-index: 1;
    //pointer-events: none;
    overflow: hidden;
    bottom: 0;
    left: 0;
    //transform: translateY(225px);

    @media (max-width: $screen-sm) {
      height: 160px;
      //transform: translateY(100px);
    }

    img {
      height: 100%;
      width: auto;
      display: inline-block;
      border-radius: 8px;
    }
  }

  .swiper-slide {
    width: auto!important;
  }
}

.film-road-map-block {
  position: relative;
  margin-top: -1px;
  padding: 180px 0 138px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, .1);
  background-image: url("../../assets/img/purpose-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: $light-color;
  @include parallax;

  @media (max-width: $screen-sm) {
    padding: 180px 0 80px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 101px;
    background: url("../../assets/img/ripped-3.png") repeat-x top;
  }

  &__title {
    //@include pixel-title-typography;
    font-size: 3rem;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 150%;
    font-weight: 800;
    margin-bottom: 0;
    text-align: center;

    @media (max-width: $screen-sm) {
      font-size: 2rem;
    }
  }

  &__description {
    font-weight: 400;
    font-size: 22px;
    line-height: 150%;
    text-align: center;
    color: #EEE9E4;
    margin: 0 0 80px;

    @media (max-width: $screen-md) {
      padding: 0 20px;
      margin: 0 0 56px;
      font-weight: 800;
      font-size: 24px;
      line-height: 150%;
    }
  }

  & + .scissor-divider {
    padding: 0;
  }

  .sign-up-next {
    font-size: 0px;
    margin-bottom: 80px;

    .sign-up-next-link {
      display: block;
      width: 441px;
      height: 80px;
      margin: auto;
      background: url("../../assets/img/sign-up-for-next.svg") no-repeat center;
      background-size: contain;
      cursor: pointer;
      text-indent: -999px;

      @media (max-width: $screen-sm) {
        width: 331px;
        height: 61px;
      }

      @media (max-width: $screen-xs) {
        width: 320px;
        height: 56px;
      }

      &:hover, &:focus {
        opacity: 0.8;
      }
    }
  }
}

.roadmap-block {
  position: relative;
  margin-top: 138px;
  color: $light-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  & + .scissor-divider {
    padding: 0;
  }

  @media (max-width: $screen-sm) {
    margin-top: 100px;
  }

  &__main-description {
    font-weight: 800;
    font-size: 32px;
    line-height: 150%;
    text-align: center;
    color: #EEE9E4;
    margin-bottom: 116px;

    @media (max-width: $screen-sm) {
      margin-bottom: 80px;
      font-weight: 800;
      font-size: 20px;
      line-height: 150%;

      br {
        display: none;
      }
    }
  }

  &__underlined-mark {
    position: relative;
    display: inline-block;

    &::after {
      content: '';
      position: absolute;
      bottom: 4px;
      height: 16px;
      left: -5px;
      right: -5px;
      background: #0057FD;
      z-index: -1;

      @media (max-width: $screen-sm) {
        height: 10px;
        left: -3px;
        right: -3px;
      }
    }
  }

  &__title {
    //@include pixel-title-typography;
    width: 100%;
    max-width: 556px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    font-weight: 800;
    font-size: 20px;
    line-height: 150%;

    .text {
      font-weight: 800;
      font-size: 20px;
      line-height: 150%;
      color: #EEE9E4;
      @media (max-width: $screen-sm) {
        font-weight: 800;
        font-size: 14px;
        line-height: 150%;
      }
    }

    div {
      display: inline-block;
      width: calc(50% - 21px);
      box-sizing: border-box;
      margin-bottom: 40px;

      @media (max-width: $screen-sm) {
        font-weight: 800;
        font-size: 14px;
        line-height: 150%;
        margin-bottom: 24px;
      }
    }

    div:nth-child(even) {
      @media (max-width: $screen-sm) {
        text-align: right;
      }
    }

    div:nth-child(odd) {
      padding: 0 40px 0 20px;
      @media (max-width: $screen-sm) {
        padding: 0;
        font-weight: 800;
        font-size: 14px;
        line-height: 150%;
      }
    }
  }

  &__mint-button {
    display: block;
    width: 250px;
    height: 56px;
    margin: 0 auto 0;
    background-image: url("../../assets/img/roadmap-mint.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 250px 56px;
    text-indent: -9999px;

    @media (min-width: $screen-sm) {
      &:hover {
        background-size: 250px 46px;
        background-image: url("../../assets/img/roadmap-mint-hover.svg");
      }
    }


    @media (max-width: $screen-sm) {
      margin-top: 32px;
    }
  }

  &__roadmap-items {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding-top: 0;
    padding-left: 20px;
    padding-right: 20px;

    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 0;
      height: 100%;
      width: 0;
      border-right: 4px dashed #EEE9E4;
      transform: translateX(-2px);
    }

    .item {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-bottom: 92px;
      box-sizing: border-box;
      align-items: center;
      position: relative;

      &.top-space {
        padding-top: 92px;

        @media (max-width: $screen-sm) {
          padding-top: 56px;
        }
      }

      &.passed {
        &::before {
          @include passed-white-line;
        }

        .icon,
        .info {
          //opacity: .425;
        }

        .separator {
          visibility: hidden;
        }
      }

      &.current {
        &::before {
          @include passed-white-line;
          height: calc(100% / 2);

          //@media (max-width: $screen-sm) {
          //  height: calc(100% / 2);
          //}
        }
      }

      &.minor {
        @media (max-width: $screen-sm) {
          padding-bottom: 47px;
        }
      }

      &:last-of-type {
        padding-bottom: 120px;

        @media (max-width: $screen-sm) {
          padding-bottom: 80px;
        }
      }

      @media (max-width: $screen-sm) {
        padding-bottom: 56px;
      }

      .icon {
        background: no-repeat top right;
        height: 120px;
        width: 50%;

        @media (max-width: $screen-sm) {
          height: 80px;
          background-size: contain;
        }
      }

      &.minor .icon {
        height: 0;
      }

      .roadmap-cart {
        background-image: url("../../assets/img/cart.svg");
      }

      .roadmap-time {
        background-image: url("../../assets/img/roadmap-time.svg");
      }

      .roadmap-film {
        background-image: url("../../assets/img/roadmap-film.svg");
      }

      .separator {
        display: block;
        width: 40px;
        height: 10px;
        z-index: 3;
        background: url("../../assets/img/roadmap-marker.svg") no-repeat center;
        background-size: 100% 100%;
        margin: 0 60px;
        position: relative;

        @media (max-width: $screen-sm) {
          margin: 0 20px;
        }
      }

      &.minor .separator {
        background: url("../../assets/img/roadmap-rect.svg") no-repeat center;
        width: 30px;
        min-width: 30px;
        height: 30px;
        margin: 0 66px;

        @media (max-width: $screen-sm) {
          margin: 0 27px;
          width: 16px;
          min-width: 16px;
          height: 16px;
          background-size: contain;
        }
      }

      .info {
        position: relative;
        box-sizing: border-box;
        width: 50%;
      }

      .description {
        font-weight: 800;
        font-size: 32px;
        line-height: 150%;
        color: #EEE9E4;

        @media (max-width: $screen-sm) {
          font-weight: 800;
          font-size: 20px;
          line-height: 150%;
        }
      }

      &.minor .description {
        font-weight: 800;
        font-size: 20px;
        line-height: 150%;

        @media (max-width: $screen-sm) {
          font-weight: 800;
          font-size: 14px;
          line-height: 150%;
        }
      }
    }
  }

  &__sale-closed {
    width: 464px;
    height: 78px;
    background: url("../../assets/img/sale-closed.svg") no-repeat center;
    background-size: contain;
    margin-bottom: 80px;
    max-width: 464px;

    @media (max-width: $screen-sm) {
      margin-left: 20px;
      margin-right: 20px;

      width: 320px;
      height: 64px;
    }
  }

  &__current-progress {
    margin: 0 0 138px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    max-width: 894px;
    width: 100%;
    box-sizing: border-box;

    @media (max-width: $screen-md) {
      padding: 0 20px;
      flex-direction: column;
    }

    .percent {
      @include pixel-font;
      font-style: normal;
      font-weight: normal;
      font-size: 60px;
      line-height: 60px;
      color: #EEE9E4;
    }

    .separator {
      min-width: 20px;
      height: 70px;
      margin: 0 32px;
      display: inline-block;
      background: url("../../assets/img/cur-progress-separator.svg") no-repeat center;

      @media (max-width: $screen-md) {
        min-width: 100%;
        height: 20px;
        margin: 20px 0;
        background: url("../../assets/img/Frame 145.svg") no-repeat center;
      }
    }

    .text {
      font-weight: 500;
      font-size: 20px;
      line-height: 170%;
      color: #EEE9E4;

      a {
        color: #EEE9E4;
      }

      @media (max-width: $screen-md) {
        text-align: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 170%;
      }
    }
  }

  &__timer {
    padding-top: 80px;
    padding-bottom: 80px;
    position: relative;

    @media (max-width: $screen-md) {
      padding-top: 56px;
      padding-bottom: 56px;
    }

    &::before {
      @include passed-white-line;
    }

    .text {
      background: $background-color;
      position: relative;
      z-index: 2;
    }
  }
}

.purpose-block {
  position: relative;
  margin-top: -1px;
  padding: 181px 0 0px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, .1);
  background-image: url("../../assets/img/purpose-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: $light-color;
  @include parallax;

  @media (max-width: $screen-sm) {
    padding: 136px 0 80px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 101px;
    background: url("../../assets/img/ripped-3.png") repeat-x top;
  }

  //&:after {
  //  content: '';
  //  position: absolute;
  //  bottom: 0;
  //  width: 100%;
  //  height: 100px;
  //  background: url("../../assets/img/parallax-ripped-2.png") no-repeat center;
  //  background-size: 100% 100%;
  //
  //  @media (min-width: $screen-qhd-min) {
  //    height: 140px;
  //  }
  //
  //  @media (max-width: $screen-sm) {
  //    height: 50px;
  //  }
  //}

  > .title {
    @include pixel-title-typography;
    margin-bottom: 120px;

    @media (max-width: $screen-sm) {
      font-size: 24px;
      line-height: 38px;
      margin-bottom: 40px;
    }
  }

  .purpose-item {
    @include block-horizontal-paddings;
    display: flex;

    .title {
      @include parallax-description-typography;
      font-weight: 700;

      @media (max-width: $screen-sm) {
        font-size: 32px;
        width: 100%;
      }

      @media (max-width: $screen-xxs) {
        font-size: 26px;
        word-break: break-word;
      }
    }

    .description {
      @include purpose-description-typography;

      p {
        margin: 32px 0;
      }

      @media (max-width: $screen-sm) {
        width: calc(100vw - 40px);
        position: relative;
        left: -112px;
        font-weight: 600;
        font-size: 14px;
        line-height: 193%;
      }
    }

    .info {
      @media (max-width: $screen-sm) {
        width: calc(100% - 112px);
      }
    }
  }

  .icon {
    min-width: 240px;
    height: 240px;
    margin-right: 56px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @media (max-width: $screen-sm) {
      margin-right: 32px;
      min-width: 80px;
      height: 80px;
    }

    &.chair {
      background-image: url("../../assets/img/icon-chair.png");
    }

    &.people {
      background-image: url("../../assets/img/icon-people.png");
    }
  }

  &__text {
    font-style: normal;
    font-weight: bold;
    font-size: 46px;
    line-height: 170%;
    text-align: center;
    color: #EEE9E4;
    margin: 100px auto 0;
    padding: 0 15px 0;
    width: 100%;
    max-width: 1046px;
    box-sizing: border-box;

    @media (max-width: $screen-sm) {
      margin: 50px auto 0;
      font-size: 20px;
    }
    .underlined {
      position: relative;

      @media (max-width: $screen-sm) {
        display: inline-block;
      }

      @media (min-width: 400px) {
        white-space: nowrap;
      }

      &::after {
        content: "";
        position: absolute;
        width: calc(100% + 24px);
        height: 60%;
        left: -12px;
        top: 50%;
        background: #0057FD;
        transform: matrix(-1, 0, 0, 1, 0, 0);
        z-index: -1;
      }
    }
  }
}

.prizes-block {
  @include parallax;
  background-image: url(../../assets/img/parallax_bg.jpg);
}

.prizes {
  padding-top: 160px;
  box-sizing: border-box;
  color: $light-color;

  @media (max-width: $screen-sm) {
    padding-top: 72px;
  }

  > .title {
    @include pixel-title-typography;
    margin-bottom: 120px;
    line-height: 2.25rem;

    @media (max-width: $screen-sm) {
      font-size: 24px;
      line-height: 38px;
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 60px;
    }
  }

  .cards {
    @include block-horizontal-paddings;
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
    max-width: 1760px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: $screen-md) {
      flex-direction: column;
      margin-bottom: 0;
      align-items: center;
    }

    &.by-three {
      .card {
        width: 30%;

        @media (max-width: $screen-md) {
          width: 100%;
        }
      }
    }

    &.by-two {
      .card {
        width: 48%;

        @media (max-width: $screen-md) {
          width: 100%;
        }
      }
    }
  }

  .card {
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.04);
    box-shadow: none;
    backdrop-filter: blur(100px);
    @include strange-border;

    @media (max-width: $screen-md) {
      margin-bottom: 40px;
    }

    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      @media (max-width: $screen-lg) {
        flex-direction: column;
      }

      @media (max-width: $screen-sm) {
        padding-top: 0px !important;
      }

      &::before {
        content: '';
        display: inline-block;
        width: 40px;
        height: 40px;
        background: url("../../assets/img/icon-goblet.svg") no-repeat top center;
        background-size: contain;

        @media (max-width: $screen-sm) {
          width: 32px;
          height: 32px;
        }
      }
    }

    .description {
      @media (max-width: $screen-sm) {
        margin-top: 16px;
      }
    }
  }

  .no-break {
    white-space: nowrap;
    margin: 0 0 0 32px;

    @media (max-width: $screen-lg) {
      margin: 20px 0 0 0;
    }
  }
}

.card {
  @include base-animation;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: $light-color;
  text-align: center;
  background: rgba(255, 255, 255, 0.04);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(100px);
  //border: 2px solid rgba(255, 255, 255, 0.5);
  //border-image-source: conic-gradient(from 180deg at 50% 50%, rgba(255, 255, 255, 0.5) 0deg, rgba(255, 255, 255, 0) 331.12deg, rgba(255, 255, 255, 0.5) 360deg);
  border-radius: 34px;
  padding: 40px;
  box-sizing: border-box;

  &.small {
    @media (min-width: $screen-sm) {
      padding: 40px 60px;
    }
  }

  @media (max-width: $screen-lg) {
    max-width: 607px;
  }

  .title {
    @include card-title-typography;
    //background: url("../../assets/img/icon-goblet.svg") no-repeat top center;
    box-sizing: border-box;
    //padding-top: 80px;
    padding-left: 0;

    @media (max-width: $screen-sm) {
      background-size: 32px 32px;
      box-sizing: border-box;
      padding-top: 56px !important;
    }
  }

  .description {
    @include card-description-typography;
    margin-top: 24px;
  }
}

.main-prize-box {
  position: relative;
  padding-bottom: 140px;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -50px;
    width: 100%;
    height: 100px;
    background: url("../../assets/img/ripped-both-sides.png") no-repeat center;
    background-size: 100% 100%;
    z-index: 1;

    @media (min-width: $screen-qhd-min) {
      bottom: -70px;
      height: 140px;
    }

    @media (max-width: $screen-sm) {
      height: 50px;
      bottom: -25px;
      background-size: cover;
    }
  }
}

.main-prize {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $screen-sm) {
    padding: 0 20px;
  }

  &:before, &:after {
    content: '';
    height: 34px;
    flex: 1 1;

    @media (max-width: $screen-sm) {
      display: none;
    }
  }

  &:before {
    left: 0px;
    background: url("../../assets/img/short-bricks-left.png") no-repeat right center;
  }

  &:after {
    right: 0px;
    background: url("../../assets/img/short-bricks-right.png") no-repeat left center;
  }

  .card {
    width: 932px;
    background-color: $main-color;
    margin: 0 32px;

    @media (max-width: $screen-lg) {
      width: 100%;
    }

    @media (max-width: $screen-sm) {
      margin: 0 auto;
    }
  }

  .description {
    @media (max-width: $screen-sm) {
      font-weight: 600;
      font-size: 14px;
      line-height: 193%;
    }
  }

  .title {
    @include card-title-typography;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0;
    background: none;

    @media (max-width: $screen-sm) {
      background: url("../../assets/img/icon-goblet.svg") no-repeat top center;
      background-size: 32px 32px;
      box-sizing: border-box;
      padding-top: 56px !important;
    }

    &::before,
    &::after {
      content: '';
      display: inline-block;
      width: 40px;
      min-width: 40px;
      height: 40px;
      background: url("../../assets/img/icon-goblet.svg") no-repeat center;
      background-size: contain;
      @media (max-width: $screen-sm) {
        display: none;
      }
    }

    &::before {
      margin-right: 30px;
    }

    &::after {
      margin-left: 30px;
    }
  }
}

footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  //margin-top: 160px;
  color: $light-color;
  padding-bottom: 80px;
  box-sizing: border-box;

  @media (max-width: $screen-sm) {
    margin-top: 80px;
  }

  .link {
    @include footer-link-typography;
    display: block;
    color: $light-color;
    text-decoration: none;
  }

  .smart-contract {
    padding: 3px 46px;
    margin-bottom: 56px;
    width: 305px;
    height: 80px;
    display: block;
    box-sizing: border-box;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: -9999px;
    background-image: url("../../assets/img/border-white.svg");

    @media (min-width: $screen-sm) {
      &:hover {
        background-image: url("../../assets/img/border-white-hover.svg");
        background-size: contain;
        color: $black-color;
      }
    }
  }

  .terms {
    margin-top: 56px;
    padding-bottom: 4px;
    box-sizing: border-box;
    border-bottom: 2px solid $light-color;
    color: #EEE9E4;

    &:hover {
      border-bottom-color: transparent;
      color: #EEE9E4;
    }
  }
}

.uppercase {
  text-transform: uppercase;
}
