@import "src/scss/variables";
@import "src/scss/mixins";

.social {
  display: flex;

  .link {
    width: 50px;
    height: 40px;
    margin: 0 40px;
    text-indent: -9999px;

    @media (max-width: 400px) {
      margin: 0 28px;
    }

    @media (min-width: $screen-sm) {
      &:hover {
        opacity: 0.5;
      }
    }

    &.twitter {
      background: url("../../../assets/img/icon-twitter.svg") no-repeat center;
    }

    &.instagram {
      background: url("../../../assets/img/inst.svg") no-repeat center;
    }

    &.tiktok {
      background: url("../../../assets/img/tik.svg") no-repeat center;
    }

    &.discord {
      background: url("../../../assets/img/icon-discord.svg") no-repeat center;
    }

    &.opensea {
      background: url("../../../assets/img/opensea-whire.svg") no-repeat center;
    }
  }
}
