@import "src/scss/variables";
@import "src/scss/mixins";

.button {
  @include button-ghost;

  &.filled-main {
    background-color: $main-color;
    border-color: $main-color;

    &:hover, &:focus {
      background-color: transparent;
      color: $light-color;
      border-color: $light-color;
      @media (max-width: $screen-sm) {
        background-color: $main-color !important;
        border-color: $main-color !important;
        color: $light-color !important;
      }
    }
  }

  &.filled-second {
    background: rgba(238, 233, 228, 0.2);
    border-color: rgba(60, 63, 66, 1);

    &:hover, &:focus {
      background-color: transparent;
      color: $main-color;
      border-color: $main-color;
    }
  }

  &.sign-in-up {
    @include sign-button-typography;
    @include base-font-weight;
    display: block;
    width: 360px;
    margin: 56px auto 0;
    padding: 10px 0;
    box-sizing: border-box;

    @media (max-width: $screen-sm) {
      width: 320px;
    }

    &:disabled {
      opacity: 0.5;

      &:hover {
        cursor: not-allowed;
        background-color: $main-color;
        border-color: $main-color;
        color: $light-color;
      }
    }
  }

  &.delete {
    @include button-danger;
    @include navigation-link-typography;
    display: block;
    //text-transform: uppercase;

    &:active, &:focus {
      outline: none;
    }

    &:disabled {
      background-color: $black-color;
      border-color: $black-color;

      &:hover {
        color: $light-color;
        background-color: $black-color;
        border-color: $black-color;
        cursor: default;
      }
    }
  }
}

a.button {
  text-decoration: none;
}



.main-menu-button {
  border: 0;
  background: transparent;
  width: 24px;
  height: 24px;
  cursor: pointer;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  margin: 1px 0;

  &:hover {
    span {
      background: $light-color;

      &:nth-child(even) {
        width: 24px;
      }

      &:nth-child(odd) {
        width: 60%;
      }
    }
  }

  span {
    @include base-animation;
    display: block;
    width: 24px;
    height: 4px;
    background: $light-color;
    border-radius: 2px;
    position: relative;
    z-index: 999;


    &:nth-child(even) {
      width: 60%;
    }
  }
}
