@import "src/scss/variables";
@import "src/scss/mixins";

.instructionPage {
  padding: 144px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: $screen-sm) {
    padding: 144px 10px 0;
  }
}

.title {
  @include pixel-font;
  font-weight: normal;
  font-size: 36px;
  line-height: 36px;
  text-align: center;
  color: #EEE9E4;

  @media (max-width: $screen-sm) {
    font-size: 24px;
    line-height: 24px;
  }
}

.number {
  @include pixel-font;
  font-size: 23px;
  line-height: 23px;
  display: inline-flex;
  align-items: center;
  text-align: center;
  width: 34px;
  height: 34px;
  color: #EEE9E4;
  background: #0057FD;
  justify-content: center;
  margin-right: 20px;
}

.text {
  font-weight: 600;
  font-size: 20px;
  line-height: 170%;
  color: #EEE9E4;
  width: 100%;
  max-width: 837px;
  margin: 80px 0 40px 0;
}

.image {
  display: block;
  width: 100%;
  max-width: 837px;
  border: 6px solid #EEE9E4;
  box-sizing: border-box;
  & + & {
    margin: 40px 0 0 0;
  }
}

.decoratedLink {
  color: #EEE9E4;

  &:hover {
    color: #EEE9E4;
    @media (min-width: $screen-sm) {
      text-decoration: none;
    }
  }

  @media (max-width: $screen-sm) {
    word-break: break-all;
  }
}

.socialBlock {
  padding: 80px 0 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
