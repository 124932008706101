@import "src/scss/variables";
@import "src/scss/mixins";

.layout {
  background: url("../../assets/img/promo-bg.jpg") no-repeat center;
  background-size: cover;
  min-height: 100vh;
}

.SuccessPage {
  margin-bottom: 120px;
  padding: 144px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: $screen-sm) {
    padding: 144px 10px 0;
  }

  p {
    width: 100%;
    max-width: 670px;
    font-weight: 600;
    font-size: 20px;
    line-height: 170%;
    text-align: center;
    color: #EEE9E4;
    margin-bottom: 56px;
  }
}

.title {
  @include pixel-font;
  font-weight: normal;
  font-size: 36px;
  line-height: 36px;
  text-align: center;
  color: #EEE9E4;
  margin-bottom: 56px;

  @media (max-width: $screen-sm) {
    font-size: 24px;
    line-height: 24px;
  }

  @media (max-width: 400px) {
    font-size: 19px;
    line-height: 19px;
  }
}

.number {
  @include pixel-font;
  font-size: 23px;
  line-height: 23px;
  display: inline-flex;
  align-items: center;
  text-align: center;
  width: 34px;
  height: 34px;
  color: #EEE9E4;
  background: #0057FD;
  justify-content: center;
  margin-right: 20px;
}

.text {
  font-weight: 600;
  font-size: 20px;
  line-height: 170%;
  color: #EEE9E4;
  width: 100%;
  max-width: 837px;
  margin: 80px 0 40px 0;
}

.image {
  display: block;
  width: 100%;
  max-width: 837px;
  border: 6px solid #EEE9E4;
  box-sizing: border-box;
  & + & {
    margin: 40px 0 0 0;
  }
}

.link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  width: 100%;
  max-width: 360px;
  height: 48px;
  background: #0057FD;
  border: 2px solid #0057FD;
  border-radius: 50px;
  color: #EEE9E4;
  box-sizing: border-box;
  text-decoration: none;
  transition: all .2s;


  &:hover {
    text-decoration: none;
    @media (min-width: $screen-sm) {
      color: #eee9e4;
      border-color: #eee9e4;
      background: transparent;
    }
  }
}

.peopleBlock {
  width: 100%;
  height: 131px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;

  &::before {
    content: '';
    display: block;
    flex: 1;
    height: 34px;
    background: url("../../assets/img/white-small-rects.svg") no-repeat right;
  }

  &::after {
    content: '';
    display: block;
    flex: 1;
    height: 34px;
    background: url("../../assets/img/white-small-rects.svg") no-repeat left;
  }

  .people {
    position: relative;
    min-width: 271px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    &::before {
      content: '';
      display: block;
      width: 55px;
      height: 100%;
      background: url("../../assets/img/man.svg") no-repeat center;
    }

    &::after {
      content: '';
      display: block;
      width: 55px;
      height: 100%;
      background: url("../../assets/img/woman.svg") no-repeat center;
    }
  }
}

.socialBlock {
  padding: 80px 0 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
